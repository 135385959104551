
import { defineComponent } from 'vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { useToast } from 'vue-toastification'
import get from '../http/get'
import del from '../http/del'
import Property from '../models/Property'
import DocumentTitle from '../components/DocumentTitle.vue'
import BottomSheet from '@/components/BottomSheet.vue'

export default defineComponent({
  name: 'AdminPropertyDetailPage',
  components: {
    PulseLoader,
    DocumentTitle,
    BottomSheet,
  },
  methods: {
    parseArr(obj: object): string[] {
      if (!obj) return []
      const ret: string[] = []
      for (let i = 0; i < 100; i++) {
        const item: string = obj[i.toString() as keyof typeof obj]
        if (!item) break
        ret.push(item)
      }
      return ret
    },
    parseNumber(value: number): string {
      if (!value) return "---"
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")
    },
    parseIdr(amount: number): string {
      if (!amount) return "Rp ---"
      if (amount >= 1e12) return `Rp ${amount/1e12} T`
      if (amount >= 1e9) return `Rp ${amount/1e9} M`
      if (amount >= 1e6) return `Rp ${amount/1e6} Jt`
      return "Rp "+this.parseNumber(amount)
    },
    handleGoBack() {
      this.$router.go(-1)
    },
    handleGoHome() {
      this.$router.push('/')
    },
    editProperty() {
      this.$router.push(`/edit/${this.id}`)
    },
    openDelete() {
      this.isDeleteOpen = true;
    },
    closeDelete() {
      this.isDeleteOpen = false;
    },
    async deleteProperty() {
      try {
        const resp = await del(`/property/admin/${this.id}`);
        if (resp.status >= 400) throw new Error(resp.data)
        this.toast.success(resp.data)
        this.$router.push('/')
      }
      catch (err: unknown) {
        if (typeof err === 'string') this.toast.error(err)
        else if (err instanceof Error) {
          if (err.message.length > 50) this.toast.error('Unknown error')
          else this.toast.error(err.message)
        }
      }
    },
    handleShare() {
      navigator.clipboard.writeText(`${this.property.judul}\n${process.env.VUE_APP_WEB_URL}${this.$route.fullPath}`)
      this.toast.info('Link berhasil disalin ke clipboard')
    },
    handleSave() {
      this.toast.info('Fitur akan segera tersedia')
    },
    handlePhone() {
      location.href = 'tel:+6281946800306'
    },
    handleWhatsapp() {
      location.href = `https://wa.me/+6281224579905?text=Halo Bu Leonny, saya [NAMA] ingin menanyakan mengenai ${this.property.kategori.toLowerCase()} ${this.property.status.toLowerCase()} yang ada pada ${process.env.VUE_APP_WEB_URL}${this.$route.fullPath}`
    },
    async loadData() {
      try {
        const resp = await get(`/property/admin/${this.id}`);
        if (resp.status >= 400) throw new Error(resp.data)
        this.property = resp.data
      }
      catch (err: unknown) {
        if (typeof err === 'string') this.toast.error(err)
        else if (err instanceof Error) {
          if (err.message.length > 50) this.toast.error('Unknown error')
          else this.toast.error(err.message)
        }
      }
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      id: this.$route.params.id,
      property: {} as Property,
      color: '#ef4444',
      isDeleteOpen: false,
    }
  },
  mounted() {
    this.loadData()
  },
})
