import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import './assets/global.css'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faBookmark as faBookmarkSolid, faChevronDown, faEdit, faGripVertical, faList, faMagnifyingGlass, faMinus, faPhone, faPlus, faShareNodes, faSliders, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import Toast, { PluginOptions, TYPE, POSITION } from "vue-toastification"
import "vue-toastification/dist/index.css"

library.add(
  faMagnifyingGlass,
  faSliders,
  faChevronDown,
  faList,
  faGripVertical,
  faArrowLeft,
  faShareNodes,
  faBookmarkRegular,
  faBookmarkSolid,
  faPhone,
  faWhatsapp,
  faPlus,
  faMinus,
  faEdit,
  faTrash,
)

const options: PluginOptions = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  toastDefaults: {
    [TYPE.SUCCESS]: {
      position: POSITION.BOTTOM_CENTER,
      timeout: 4000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false
    },
    [TYPE.ERROR]: {
      position: POSITION.BOTTOM_CENTER,
      timeout: 4000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false
    },
    [TYPE.INFO]: {
      position: POSITION.BOTTOM_CENTER,
      timeout: 4000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false
    },
    [TYPE.WARNING]: {
      position: POSITION.BOTTOM_CENTER,
      timeout: 4000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false
    },
    [TYPE.DEFAULT]: {
      position: POSITION.BOTTOM_CENTER,
      timeout: 4000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false
    },
  }
}

const app = createApp(App)
app.use(router)
app.use(Toast, options)
app.component('fa-icon', FontAwesomeIcon)
app.mount('#app')
