
import { defineComponent } from 'vue'

export default defineComponent({
  name: "CategoryForm",
  data() {
    return {
      cities: [
        "Jakarta",
        "Bandung",
        "Cirebon",
      ],
      query: {
        kota: null,
      }
    }
  },
  methods: {
    async handleSubmit() {
      this.$emit('closeCity');
      this.$router.push({ 
        query: {
          ...this.$route.query,
          ...this.query
        } 
      });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 10);
    },
  },
})
