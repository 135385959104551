
import { defineComponent } from 'vue'
import { useToast } from 'vue-toastification'
import put from '../http/put'
import get from '../http/get'

export default defineComponent({
  name: 'EditPropertyPage',
  methods: {
    handlePhotoUpload(event: Event) {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        Array.from(target.files).forEach((file) => this.fotoNew.push(file));
        Array.from(target.files).forEach((file) => this.fotoPreviewNew.push(URL.createObjectURL(file)));
      }
    },
    removePhoto(index: number) {
      // this.foto.splice(index, 1);
      this.fotoPreview.splice(index, 1);
    },
    removePhotoNew(index: number) {
      this.fotoNew.splice(index, 1);
      this.fotoPreviewNew.splice(index, 1);
    },
    async loadData() {
      try {
        const resp = await get(`/property/admin/${this.id}`);
        if (resp.status >= 400) throw new Error(resp.data)
        // this.property = resp.data
        this.kategori = resp.data.kategori
        this.status = resp.data.status
        this.harga = resp.data.harga 
        this.hargaPerM2 = resp.data.hargaPerM2 || 0
        this.hargaPerTahun = resp.data.hargaPerTahun || 0
        this.tipe = resp.data.tipe
        this.judul = resp.data.judul || ''
        this.deskripsi = resp.data.deskripsi
        this.provinsi = resp.data.provinsi || ''
        this.kota = resp.data.kota || ''
        this.luasTanah = resp.data.luasTanah
        this.luasBangunan = resp.data.luasBangunan
        this.jumlahLantai = resp.data.jumlahLantai || 0
        this.kamarTidur = resp.data.kamarTidur || 0
        this.kamarMandi = resp.data.kamarMandi || 0
        this.kamarTidurPembantu = resp.data.kamarTidurPembantu || 0
        this.kamarMandiPembantu = resp.data.kamarMandiPembantu || 0
        this.hiddenKeyword = resp.data.hiddenKeyword || ''
        this.foto = resp.data.foto
        this.fotoPreview = resp.data.foto
      }
      catch (err: unknown) {
        if (typeof err === 'string') this.toast.error(err)
        else if (err instanceof Error) {
          if (err.message.length > 50) this.toast.error('Unknown error')
          else this.toast.error(err.message)
        }
      }
    },
    async handleSubmit() {
      try {
        const formData = new FormData();

        // Append non-file fields
        formData.append('kategori', this.kategori);
        formData.append('status', this.status);
        formData.append('harga', this.harga.toString());
        formData.append('hargaPerM2', this.hargaPerM2.toString());
        formData.append('hargaPerTahun', this.hargaPerTahun.toString());
        formData.append('tipe', this.tipe);
        formData.append('judul', this.judul);
        formData.append('deskripsi', this.deskripsi);
        formData.append('provinsi', this.provinsi);
        formData.append('kota', this.kota);
        formData.append('luasTanah', this.luasTanah.toString());
        formData.append('luasBangunan', this.luasBangunan.toString());
        formData.append('jumlahLantai', this.jumlahLantai.toString());
        formData.append('kamarTidur', this.kamarTidur.toString());
        formData.append('kamarMandi', this.kamarMandi.toString());
        formData.append('kamarTidurPembantu', this.kamarTidurPembantu.toString());
        formData.append('kamarMandiPembantu', this.kamarMandiPembantu.toString());
        formData.append('hiddenKeyword', this.hiddenKeyword);

        // Append photo files
        this.foto.forEach((file) => {
          formData.append('foto', file);
        });
        this.fotoNew.forEach((file) => {
          if (file instanceof File) {
            formData.append('images', file);
          }
        });

        // Post request
        const resp = await put(`/property/admin/${this.id}`, formData, 'multipart/form-data');

        if (resp.status >= 400) throw new Error(resp.data);
        this.$router.push('/');
      } catch (err: unknown) {
        if (typeof err === 'string') this.toast.error(err);
        else if (err instanceof Error) this.toast.error(err.message);
      }
    },
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  mounted() {
    const token = localStorage.getItem('accessToken')
    if (!token) this.$router.push('/login')
    this.loadData()
  },
  data() {
    return {
      id: this.$route.params.id,
      kategori: '',
      status: '',
      harga: 0,
      hargaPerM2: 0,
      hargaPerTahun: 0,
      hideHarga: false,
      tipe: '',
      judul: '',
      deskripsi: '',
      provinsi: '',
      kota: '',
      luasTanah: 0,
      luasBangunan: 0,
      jumlahLantai: 0,
      kamarTidur: 0,
      kamarMandi: 0,
      kamarTidurPembantu: 0,
      kamarMandiPembantu: 0,
      foto: [] as string[],
      fotoNew: [] as File[],
      fotoPreview: [] as string[],
      fotoPreviewNew: [] as string[],
      hiddenKeyword: '',
    }
  },
})
