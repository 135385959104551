
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PropertiesListItem',
  props: {
    property: Object,
  },
  methods: {
    parseNumber(value: number): string {
      if (!value) return "---"
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")
    },
    parseIdr(amount: number): string {
      if (!amount) return "Rp ---"
      if (amount >= 1e12) return `Rp ${amount/1e12} T`
      if (amount >= 1e9) return `Rp ${amount/1e9} M`
      if (amount >= 1e6) return `Rp ${amount/1e6} Jt`
      return "Rp "+this.parseNumber(amount)
    },
    handleClick() {
      this.$router.push(`/property/${this.property?._id}`)
    }
  },
})
