
import { defineComponent } from 'vue'
import { useToast } from 'vue-toastification'
import post from '../http/post'

export default defineComponent({
  name: 'NewPropertyPage',
  methods: {
    handlePhotoUpload(event: Event) {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        Array.from(target.files).forEach((file) => this.foto.push(file));
        Array.from(target.files).forEach((file) => this.fotoPreview.push(URL.createObjectURL(file)));
      }
    },
    removePhoto(index: number) {
      // this.foto = this.foto.filter((f,i) => i != index)
      this.foto.splice(index, 1);
      this.fotoPreview.splice(index, 1);
    },
    async handleSubmit() {
      try {
        this.isLoading = true;
        const formData = new FormData();

        // Append non-file fields
        formData.append('kategori', this.kategori);
        formData.append('status', this.status);
        formData.append('harga', this.harga.toString());
        formData.append('hargaPerM2', this.hargaPerM2.toString());
        formData.append('hargaPerTahun', this.hargaPerTahun.toString());
        formData.append('tipe', this.tipe);
        formData.append('judul', this.judul);
        formData.append('deskripsi', this.deskripsi);
        formData.append('provinsi', this.provinsi);
        formData.append('kota', this.kota);
        formData.append('luasTanah', this.luasTanah.toString());
        formData.append('luasBangunan', this.luasBangunan.toString());
        formData.append('jumlahLantai', this.jumlahLantai.toString());
        formData.append('kamarTidur', this.kamarTidur.toString());
        formData.append('kamarMandi', this.kamarMandi.toString());
        formData.append('kamarTidurPembantu', this.kamarTidurPembantu.toString());
        formData.append('kamarMandiPembantu', this.kamarMandiPembantu.toString());
        formData.append('hiddenKeyword', this.hiddenKeyword);

        // Append photo files
        this.foto.forEach((file) => {
          if (file instanceof File) {
            formData.append('images', file);
          }
        });

        // Post request
        const resp = await post('/property', formData, 'multipart/form-data');

        if (resp.status >= 400) throw new Error(resp.data);
        this.isLoading = true;
        this.$router.push('/');
      } catch (err: unknown) {
        if (typeof err === 'string') this.toast.error(err);
        else if (err instanceof Error) this.toast.error(err.message);
      }
    },
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  mounted() {
    const token = localStorage.getItem('accessToken')
    if (!token) this.$router.push('/login')
  },
  data() {
    return {
      kategori: '',
      status: '',
      harga: 0,
      hargaPerM2: 0,
      hargaPerTahun: 0,
      hideHarga: false,
      tipe: '',
      judul: '',
      deskripsi: '',
      provinsi: '',
      kota: '',
      luasTanah: 0,
      luasBangunan: 0,
      jumlahLantai: 0,
      kamarTidur: 0,
      kamarMandi: 0,
      kamarTidurPembantu: 0,
      kamarMandiPembantu: 0,
      foto: [] as File[],
      fotoPreview: [] as string[],
      hiddenKeyword: '',
      isLoading: false,
    }
  },
})
