
import { defineComponent } from 'vue'

import { useToast } from 'vue-toastification'

import get from '../http/get'
import Property from '../models/Property'
import SearchBar from '../components/SearchBar.vue'
import Filter from '../components/Filter.vue'
import Properties from '../components/Properties.vue'
import DocumentTitle from '../components/DocumentTitle.vue'

export default defineComponent({
  name: 'AdminDashboardPage',
  components: {
    SearchBar,
    Filter,
    Properties,
    DocumentTitle,
  },
  methods: {
    handleGoToNewProperty() {
      this.$router.push('/new')
    },
    handleStickyTop() {
      const section = document.getElementById("sticky-top")
      window.onscroll = () => {
        const sectionTop = section?.getBoundingClientRect().top
        if (sectionTop == 0) {
          section?.classList.remove("bg-transparent")
          section?.classList.add("bg-white")
        }
        else {
          section?.classList.add("bg-transparent")
          section?.classList.remove("bg-white")
        }
      }
    },
    async loadData() {
      try {
        this.properties = null;
        const resp = await get('/property/admin', this.$route.query);
        if (resp.status >= 400) throw new Error(resp.data);
        this.properties = resp.data.properties;
        this.page = resp.data.query.page;
        this.maxPage = resp.data.maxPage;
      }
      catch (err: unknown) {
        if (typeof err === 'string') this.toast.error(err);
        else if (err instanceof Error) {
          if (err.message.length > 50) this.toast.error('Unknown error');
          else this.toast.error(err.message);
        }
      }
    },
    changePage(newPage: number) {
      this.page = newPage;
      this.$router.push({ 
        query: { 
          ...this.$route.query,
          page: newPage,
        }
      });
      // setTimeout(() => {
      //   this.loadData();
      // }, 10);
    },
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        this.loadData();
      },
      immediate: true, // Runs the watcher immediately when the component is mounted
      deep: true // Watches for deep changes in query parameters
    }
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      properties: null as Property[] | null,
      page: 0,
      maxPage: 0,
    }
  },
  mounted() {
    const token = localStorage.getItem('accessToken')
    if (!token) this.$router.push('/login')
    this.handleStickyTop();
    this.loadData();
    this.page = parseInt(this.$route.query.page as string) || 1;
  },
})
