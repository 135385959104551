import axios from 'axios'

import refreshAuth from '../utils/refreshAuth'

const get = async (url: string, query: any = {}) => {
  console.log(query)
  const execute = async () => {
    const token = localStorage.getItem('accessToken')
    const api = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      params: query,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      validateStatus: (stat) => true
    })
    return await api.get(url)
  }

  let resp = await execute()
  if (resp.data === 'Token kadaluwarsa') {
    await refreshAuth()
    resp = await execute()
  }
  return resp
}

export default get