import axios from 'axios'
import refreshAuth from '../utils/refreshAuth'

const post = async (url: string, data: object, contentType?: string) => {
  const execute = async () => {
    const token = localStorage.getItem('accessToken')
    const api = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        'Content-Type': contentType || 'application/json',
        'Authorization': `Bearer ${token}`
      },
      validateStatus: (stat) => true
    })
    return await api.post(url, data)
  }

  let resp = await execute()
  if (resp.data === 'Token kadaluwarsa') {
    await refreshAuth()
    resp = await execute()
  }
  return resp
}

export default post