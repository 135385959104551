import axios from 'axios'
import refreshAuth from '../utils/refreshAuth'

const del = async (url: string) => {
  const execute = async () => {
    const token = localStorage.getItem('accessToken')
    const api = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      validateStatus: (stat) => true
    })
    return await api.delete(url)
  }

  let resp = await execute()
  if (resp.data === 'Token kadaluwarsa') {
    await refreshAuth()
    resp = await execute()
  }
  return resp
}

export default del