
import { defineComponent } from 'vue'

export default defineComponent({
  name: "CategoryForm",
  data() {
    return {
      statuses: [
        "Jual",
        "Sewa",
        "Jual/Sewa",
      ],
      query: {
        status: null,
      }
    }
  },
  methods: {
    async handleSubmit() {
      this.$emit('closeStatus');
      this.$router.push({ 
        query: {
          ...this.$route.query,
          ...this.query
        } 
      });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 10);
    },
  },
})
