import { createRouter, createWebHistory } from 'vue-router'

import DashboardPage from '../pages/Dashboard.vue'
import PropertyDetailPage from '../pages/PropertyDetail.vue'
import LoginPage from '../pages/Login.vue'
import NewPropertyPage from '../pages/NewProperty.vue'
import AdminDashboardPage from '../pages/AdminDashboard.vue'
import AdminPropertyDetailPage from '../pages/AdminPropertyDetail.vue'
import EditPropertyPage from '../pages/EditProperty.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // { path: '/', component: DashboardPage },
    // { path: '/property/:id', component: PropertyDetailPage },
    { path: '/login', component: LoginPage },
    { path: '/', component: AdminDashboardPage },
    { path: '/property/:id', component: AdminPropertyDetailPage },
    { path: '/new', component: NewPropertyPage },
    { path: '/edit/:id', component: EditPropertyPage },
  ],
})

export default router